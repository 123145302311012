import React, { useState, useContext, useEffect } from "react";
import { Container } from "aurelia-framework";
import update from "immutability-helper";
import {
    Panel,
    Divider,
    Checkbox,
    Row,
    Col,
    Button,
    IconButton,
    ButtonToolbar,
    InputGroup,
    InputNumber,
} from "rsuite";
import moment from "moment";
import { OfferPreviewDetails } from "./offer-preview-details";
import { OfferPreviewDuration } from "./offer-preview-duration";
import { OfferPreviewSummary } from "./offer-preview-summary";
import { OfferPreviewPricing } from "./offer-preview-pricing";
import { OrderContext } from "../helper/order-context";
import { Client } from "../../../api/client";
import "./offer-preview.less";

export const OfferPreview = ({
    selectedSearchItem,
    participants,
    firstDate,
    clearSelectedItem,
}) => {
    const client = Container.instance.get(Client);

    const [accommodationDuration, setAccommodationDuration] = useState(selectedSearchItem.durationDays ?? 1);
    const [timeAndPriceOptions, setTimeAndPriceOptions] = useState({
        day1: null,
        day2: null,
        timeAndPrice1: [],
        timeAndPrice2: [],
    });

    const [selectedDate, setSelectedDate] = useState(firstDate);

    const [
        selectedTimeAndPriceOption,
        setSelectedTimeAndPriceOption,
    ] = useState(null);
    const [selectedTime, setSelectedTime] = useState();
    const [isServiceOptional, setIsServiceOptional] = useState(false);
    const [additionalOptions, setAdditionalOptions] = useState([]);
    const [selectedAdditionalOptions, setSelectedAdditionalOptions] = useState(
        {}
    );
    const { title, product, provider, id, modelId } = selectedSearchItem;
    const { description, teasers, images } = product;
    const isAccommodationTab =
        provider && provider.includes("tourism-accommodation");

    const { updateEventItem, order } = useContext(OrderContext);

    useEffect(() => {
        getPricingDetails();
    }, [accommodationDuration, selectedDate]);

    const onOrderConfirm = () => {
        const offset = moment().local().utcOffset();
        const fromDate = moment(selectedTime.date)
            .subtract(isAccommodationTab ? 0 : offset, "minutes") //compensate local timezone offset but not for accommodations
            .format();
        const payload = {
            provider: provider,
            product: {
                id: id,
                modelId: modelId,
            },
            fromDate,
        };
        if (
            selectedAdditionalOptions &&
            Object.keys(selectedAdditionalOptions).length > 0
        ) {
            payload.configuratorAdditionalOptions = Object.values(
                selectedAdditionalOptions
            );
        }

        if (isAccommodationTab) {
            payload.toDate = moment(fromDate)
                .add(accommodationDuration, "days")
                .endOf("day")
                .toISOString();
            payload.optional = isServiceOptional;
        }
        updateEventItem(payload);
        clearSelectedItem();
    };

    const getPricingDetails = async () => {
        const date = moment(selectedDate).format("YYYY-MM-DD");
        const url = `offer-search-prices/${provider}/${order.id}?amount=${participants}&date=${date}&resultId=${id}&duration=${accommodationDuration}&embeds[]=additionalOptions`;
        try {
            const response = await client.get(url);
            setAdditionalOptions(response.additionalOptions);
            const details = mapTimeAndPriceInfo(response.prices);
            setTimeAndPriceOptions(details);
        } catch (error) {
            console.error("Error fetching pricings", error);
        }
        setSelectedTime();
    };
    // TIME AND PRICE
    const mapTimeAndPriceInfo = (infos) => {
        const start = infos[0];
        const startUTC = moment.utc(start.fromDate);
        const end = infos[infos.length - 1];
        const mappedTimeAndPriceInfo = {
            day1: startUTC.format("DD.MM.YYYY"),
            day2: moment.utc(end.fromDate).format("DD.MM.YYYY"),
            timeAndPrice1: [],
            timeAndPrice2: [],
        };
        infos.forEach((info) => {
            const dateOriginal = info.fromDate;
            const originalDateUTC = moment.utc(info.fromDate);
            const time = originalDateUTC.format("HH:mm");
            const price = info.pricePerPerson.amount;
            const currency = info.pricePerPerson.currency;

            const data = {
                dateOriginal,
                price,
                currency,
                time,
            };

            const isOnStartDay = startUTC.isSame(originalDateUTC, "day");
            if (isOnStartDay) {
                mappedTimeAndPriceInfo.timeAndPrice1 = calculateTimeAndPricePerPerson(
                    mappedTimeAndPriceInfo.timeAndPrice1,
                    data
                );
            } else {
                if (!mappedTimeAndPriceInfo.day2) {
                }
                mappedTimeAndPriceInfo.timeAndPrice2 = calculateTimeAndPricePerPerson(
                    mappedTimeAndPriceInfo.timeAndPrice2,
                    data
                );
            }
        });
        return mappedTimeAndPriceInfo;
    };

    const calculateTimeAndPricePerPerson = (timeAndPrice, data) => {
        const { dateOriginal, price, currency, time } = data;
        const index = timeAndPrice.findIndex(
            (timeInfo) => timeInfo.price === price
        );
        if (index > -1) {
            timeAndPrice[index].times.push({ hour: time, date: dateOriginal });
        } else {
            timeAndPrice.push({
                price,
                currency,
                times: [{ hour: time, date: dateOriginal }],
            });
        }
        return timeAndPrice;
    };

    const selectTime = (timeInfo, time) => {
        setSelectedTimeAndPriceOption(timeInfo);
        setSelectedTime(time);
    };

    const onChangeDuration = (duration) => {
        setAccommodationDuration(duration);
        setSelectedTimeAndPriceOption(null);
    };

    const changeAdditionalService = (identifier, value) => {
        if (value === 0) {
            setSelectedAdditionalOptions(
                update(selectedAdditionalOptions, { $unset: [identifier] })
            );
        } else {
            if (!selectedAdditionalOptions[identifier]) {
                setSelectedAdditionalOptions(
                    update(selectedAdditionalOptions, {
                        [identifier]: {
                            $set: {
                                amount: value,
                                optional: false,
                                value: identifier,
                            },
                        },
                    })
                );
            } else {
                setSelectedAdditionalOptions(
                    update(selectedAdditionalOptions, {
                        [identifier]: { amount: { $set: value } },
                    })
                );
            }
        }
    };

    return (
        <Panel bordered className="preview-view">
            <OfferPreviewDetails
                title={title}
                teasers={teasers}
                images={images}
                description={description}
                closePreview={clearSelectedItem}
                product={product}
            />
            {isAccommodationTab && (
                <OfferPreviewDuration
                    accommodationDuration={accommodationDuration}
                    setAccommodationDuration={onChangeDuration}
                    isServiceOptional={isServiceOptional}
                    setIsServiceOptional={setIsServiceOptional}
                />
            )}
            <div style={{ marginBottom: "10px" }}>
                { additionalOptions && additionalOptions.map((option) => {
                    return (
                        <div
                            className="row"
                            style={{ marginTop: "10px" }}
                            key={option.value}
                        >
                            <div className="col-xs-2">
                                <InputNumber
                                    className="custom-input-number"
                                    value={
                                        selectedAdditionalOptions?.[
                                            option.value
                                        ]?.amount ?? 0
                                    }
                                    onChange={(value) => {
                                        changeAdditionalService(
                                            option.value,
                                            Number(value)
                                        );
                                    }}
                                    max={999999}
                                    min={0}
                                />
                            </div>
                            <div className="col-xs-6">{option.label}</div>
                            <div className="col-xs-4">
                                {selectedAdditionalOptions[option.value] && (
                                    <Checkbox
                                        checked={
                                            selectedAdditionalOptions?.[
                                                option.value
                                            ]?.optional ?? 0
                                        }
                                        onChange={(event, value) => {
                                            setSelectedAdditionalOptions(
                                                update(
                                                    selectedAdditionalOptions,
                                                    {
                                                        [option.value]: {
                                                            optional: {
                                                                $set: value,
                                                            },
                                                        },
                                                    }
                                                )
                                            );
                                        }}
                                    >
                                        Optionale Leistung
                                    </Checkbox>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <ButtonToolbar>
                <Button
                    onClick={() => {
                        setSelectedDate(
                            moment(selectedDate)
                                .subtract(1, "days")
                                .format("YYYY-MM-DD")
                        );
                    }}
                >
                    <i className="glyphicon glyphicon-arrow-left" />
                </Button>

                <Button
                    onClick={() => {
                        setSelectedDate(
                            moment(selectedDate)
                                .add(1, "days")
                                .format("YYYY-MM-DD")
                        );
                    }}
                >
                    <i className="glyphicon glyphicon-arrow-right" />
                </Button>
            </ButtonToolbar>

            <OfferPreviewPricing
                selectedTime={selectedTime}
                isAccommodationTab={isAccommodationTab}
                timeAndPriceOptions={timeAndPriceOptions}
                onSelectTime={selectTime}
            />
            <Divider />
            <OfferPreviewSummary
                participants={participants}
                selectedTimeAndPriceOption={selectedTimeAndPriceOption}
                onOrderConfirm={onOrderConfirm}
                isSubmitDisabled={!selectedTime}
            />
        </Panel>
    );
};
