import React, { useContext } from "react";
import clsx from "clsx";
import AdminIcon from "@rsuite/icons/Admin";
import { OrderContext } from "../helper/order-context";
import { sum } from "../../../money/money-tools";
import { formatPriceWithCommas } from "../helper/utility";

const PriceInfo = ({ label, prices, doublePrices, participantsCount }) => {
  let singlePricePerPerson = sum(prices);
  singlePricePerPerson = formatPriceWithCommas(
    singlePricePerPerson?.amount,
    singlePricePerPerson?.currency
  );

  let doublePricePerPerson = sum(doublePrices);
  doublePricePerPerson = formatPriceWithCommas(
    doublePricePerPerson?.amount,
    doublePricePerPerson?.currency
  );

  return (
    <div>
      <span>{label}</span>
      <span>{doublePricePerPerson} DZ / {singlePricePerPerson} EZ </span>
    </div>
  );
};

export const OfferPricing = ({ className }) => {
  const { orderItems, participantsOrder: totalParticipants } = useContext(
    OrderContext
  );
  const requiredOrderItems = orderItems.filter((order) => !order.optional);
  const isPriceAvailableToDisplay = requiredOrderItems.length > 0;

  const singlePurchases = orderItems.filter((item) => {

    if (item.provider !== 'tourism-accommodation/room') {
      return true;
    }

    return item?.data?.singleAccommodation === true
  }).map(
    (i) => i.offerCalculatedItemPriceWithOptionsPerPerson
  );

  const singleRetail = orderItems.filter((item) => {
    if (item.provider !== 'tourism-accommodation/room') {
      return true;
    }

    return item?.data?.singleAccommodation === true
  }).map(
    (i) => i.offerItemPriceWithOptionsPerPerson
  );

  const doublePurchases = orderItems.filter((item) => {
    if (item.provider !== 'tourism-accommodation/room') {
      return true;
    }

    return item?.data?.singleAccommodation !== true
  }).map(
    (i) => i.offerCalculatedItemPriceWithOptionsPerPerson
  );

  const doubleRetail = orderItems.filter((item) => {
    if (item.provider !== 'tourism-accommodation/room') {
      return true;
    }

    return item?.data?.singleAccommodation !== true
  }).map(
    (i) => i.offerItemPriceWithOptionsPerPerson
  );

  return isPriceAvailableToDisplay ? (
    <div className={clsx(className)}>
      <PriceInfo label={"EK p.P:"} prices={singlePurchases} doublePrices={doublePurchases} />
      <PriceInfo
        label={"VK p.P:"}
        prices={singleRetail} doublePrices={doubleRetail}
      />
    </div>
  ) : null;
};
